.imageContainer {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
}

.imageContainer > div {
  position: unset !important;
}

.image {
  object-fit: cover;
  width: 100% !important;
  position: relative !important;
}